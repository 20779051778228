export const dataProjects = [
	{
		title: `Trash Crab`,
		type: `Jeu`,
		mini_desc: `Un MMORPG sur unity`,
		skills: ['Unity', 'C#', 'Mirror Networking', 'Blender'],
		start_date: `23-06-2024`,
		end_date: `En développement`,
		desc: `C'est un MMORPG façon Elden Ring dans l'océan où l'on incarne un crabe.`,
		icon: `images/works/trash-crab/icon.png`,
		images: [],
		link: ``,
		school: false,
	},
	{
		title: `League of Losers`,
		type: `Jeu`,
		mini_desc: `Remake du jeu League of Legends`,
		skills: ['Java', 'Swing', 'Socket-Networking', 'Spatial Hashing'],
		start_date: `19-06-2024`,
		end_date: `En développement`,
		desc: `Ceci est un remake du jeu League of Legends avec une architecture client/serveur et des essais de plusieurs méthodes d'optimisation, comme le spatial hashing.`,
		icon: `images/works/league-of-losers/icon.png`,
		images: [`images/works/league-of-losers/image-1.png`],
		link: `https://github.com/Syn0xy/League-of-Losers`,
		school: false,
	},
	{
		title: `Personal Agario`,
		type: `Jeu`,
		mini_desc: `Remake d'Agario`,
		skills: ['Java', 'Swing', 'Socket-Networking'],
		start_date: `11-06-2024`,
		end_date: `Bientôt fini`,
		desc: `Ceci est un remake du célébre jeu Agario avec également une architecture client/serveur grâce à mon projet de Socket-Networking.`,
		icon: `images/works/personal-agario.png`,
		images: [`images/works/personal-agario.png`],
		link: `https://github.com/Syn0xy/Personal-Agario`,
		school: false,
	},
	{
		title: `Socket Networking`,
		type: `Framework`,
		mini_desc: `Un framework pour gérer le multijoueur en Java`,
		skills: ['Java', 'Socket', 'Jackson', 'Json'],
		start_date: `02/06/2024`,
		end_date: `11/06/2024`,
		desc: `Refonte de la bibliothèque Socket.io de JavaScript en Java, réalisée uniquement grâce à mes connaissances.`,
		icon: `images/works/personal-network-server.png`,
		images: [],
		link: `https://github.com/Syn0xy/Socket-Networking`,
		school: false,
	},
	{
		title: `Galaxy`,
		type: `Jeu visuel`,
		mini_desc: `Simulation de gravité`,
		skills: ['Java', 'Swing'],
		start_date: `04-03-2024`,
		end_date: `En développement`,
		desc: ``,
		icon: `images/works/galaxy/icon.png`,
		images: [`images/works/galaxy/image-2.png`],
		link: ``,
		school: false,
	},
	{
		title: `Serenity Heaven`,
		type: `Jeu`,
		mini_desc: `RPG 2D Open world`,
		skills: ['Java', 'Swing'],
		start_date: `04-03-2024`,
		end_date: `En développement`,
		desc: ``,
		icon: `images/works/serenity-heaven/icon.png`,
		images: [`images/works/serenity-heaven/image-1.png`],
		link: `https://github.com/Syn0xy/Serenity-Heaven`,
		school: false,
	},
	{
		title: `Personal 3D Engine`,
		type: `Application`,
		mini_desc: `Mon propre moteur 3D en Java`,
		skills: ['Java', 'Swing'],
		start_date: `05-21-2023`,
		end_date: `En développement`,
		desc: `Mon propre moteur 3D, réalisé uniquement grâce à mes compétences en mathématiques, simple et assez efficace !`,
		icon: `images/works/personal-3d-engine.png`,
		images: [`images/works/personal-3d-engine.png`],
		link: `https://github.com/Syn0xy/Personal-3D-Engine`,
		school: false,
	},
	{
		title: `Physics Sandbox`,
		type: `Jeu`,
		mini_desc: `Simulation de matières en 2D`,
		skills: ['Java', 'Swing'],
		start_date: `18-01-2024`,
		end_date: `En développement`,
		desc: ``,
		icon: `images/works/physics-sandbox.png`,
		images: [`images/works/physics-sandbox.png`],
		link: `https://github.com/Syn0xy/Physics-Sandbox`,
		school: false,
	},
	{
		title: `Portfolio`,
		type: `Web`,
		mini_desc: `Vous êtes dessus !`,
		skills: ['HTML', 'CSS', 'JavaScript'],
		start_date: `24-09-2023`,
		end_date: `En développement`,
		desc: ``,
		icon: `images/works/portfolio/icon.png`,
		images: [`images/works/portfolio/image-1.png`],
		link: `https://github.com/Syn0xy/Portfolio`,
		school: false,
	},
	{
		title: `Personal Tetris`,
		type: `Jeu`,
		mini_desc: `Remake de Tetris`,
		skills: ['Java', 'Swing'],
		start_date: `27-11-2023`,
		end_date: `10-12-2023`,
		desc: ``,
		icon: `images/works/personal-tetris.png`,
		images: [`images/works/personal-tetris.png`],
		link: `https://github.com/Syn0xy/Personal-Tetris`,
		school: false,
	},
	{
		title: `Personal Minesweeper`,
		type: `Jeu`,
		mini_desc: `Remake du Demineur`,
		skills: ['Java', 'Swing'],
		start_date: `09-11-2023`,
		end_date: `10-11-2023`,
		desc: `Ceci est un remake du Demineur Classic avec les mêmes règles mais plus de fonctionnalités !`,
		icon: `images/works/personal-minesweeper.png`,
		images: [`images/works/personal-minesweeper.png`],
		link: `https://github.com/Syn0xy/Personal-Minesweeper`,
		school: false,
	},
	{
		title: `Schonger`,
		type: `Bot Discord`,
		mini_desc: `Un jeu "Qui est-ce ?" sur des photos quotidiennes`,
		skills: ['JavaScript', 'Discord'],
		start_date: `29-09-2023`,
		end_date: `En développement`,
		desc: `Un remake du jeu de société "Qui est-ce ?" basé sur des photos quotidiennes des utilisateurs sur un serveur`,
		icon: `images/works/discord-bot.bmp`,
		images: [],
		link: `https://github.com/Syn0xy/Schlonger`,
		school: false,
	},
	{
		title: `Pokemon Game`,
		type: `Jeu`,
		mini_desc: `Un RPG Pokemon en Java`,
		skills: ['Java', 'Swing'],
		start_date: `28-03-2023`,
		end_date: `En développement`,
		desc: `Ceci est un RPG Pokemon en monde ouvert avec les ~950 Pokemons disponibles.`,
		icon: `images/works/pokemon-game/icon.png`,
		images: [
			`images/works/pokemon-game/image-1.png`,
			`images/works/pokemon-game/image-2.png`,
		],
		link: `https://github.com/Syn0xy/PokemonGame`,
		school: false,
	},
	{
		title: `DarkWild`,
		type: `Jeu`,
		mini_desc: `Un MMORPG sur unity`,
		skills: ['Unity', 'C#', 'Mirror Networking'],
		start_date: `23-09-2022`,
		end_date: `En développement`,
		desc: `C'est un MMORPG fait le moteur Unity avec de la génération procédurale.`,
		icon: `images/works/darkwild.png`,
		images: [`images/works/darkwild.png`],
		link: ``,
		school: false,
	},
	{
		title: `Kilast2D`,
		type: `Jeu mobile`,
		mini_desc: `Un RPG 2D sur unity`,
		skills: ['Unity', 'C#'],
		start_date: ``,
		end_date: ``,
		desc: ``,
		icon: `images/works/kilast2d/icon.png`,
		images: [`images/works/kilast2d/image-1.png`],
		link: ``,
		school: false,
	},
	{
		title: `Database Management`,
		type: `Application`,
		mini_desc: `Gestion de base de données`,
		skills: ['Java', 'SQL', 'PostgreSQL', 'Git'],
		start_date: `23-01-2024`,
		end_date: `24-01-2024`,
		desc: ``,
		icon: `images/works/database-management/icon.png`,
		images: [`images/works/database-management/image-1.png`],
		link: `https://github.com/Syn0xy/Database-Management`,
		school: false,
	},
	{
		title: `Salamander Story`,
		type: `Jeu web`,
		mini_desc: ``,
		skills: ['JavaScript', 'Node.js', 'Babel.js', 'Git'],
		start_date: `12-02-2024`,
		end_date: `08-04-2024`,
		desc: ``,
		icon: `images/works/salamander-story/icon.png`,
		images: [
			`images/works/salamander-story/image-3.png`,
			`images/works/salamander-story/image-1.png`,
			`images/works/salamander-story/image-2.png`,
			`images/works/salamander-story/image-4.png`,
		],
		link: `https://gitlab.univ-lille.fr/antoine.pourthie.etu/sae-2023-groupeH-Pourthie-Dumon`,
		school: true,
	},
	{
		title: `Pizzeria`,
		type: `API REST`,
		mini_desc: ``,
		skills: ['Tomcat', 'Java EE', 'Bruno', 'Git'],
		start_date: `19-02-2024`,
		end_date: `18-03-2024`,
		desc: ``,
		icon: `images/works/pizzeria/icon.png`,
		images: [
			`images/works/pizzeria/image-1.png`,
			`images/works/pizzeria/image-2.png`,
		],
		link: `https://gitlab.univ-lille.fr/antoine.pourthie.etu/sae-rest-groupe-h-pourthie-dumon`,
		school: true,
	},
	{
		title: `Chasse au monstre`,
		type: `Application`,
		mini_desc: ``,
		skills: ['Java', 'Swing', 'Git'],
		start_date: `09-10-2023`,
		end_date: `19-01-2024`,
		desc: ``,
		icon: `images/works/chasse-au-monstre.png`,
		images: [`images/works/chasse-au-monstre.png`],
		link: `https://gitlab.univ-lille.fr/sae302/2023/K1_SAE3A`,
		school: true,
	},
	{
		title: `The Legend Of Words`,
		type: `Jeu terminal`,
		mini_desc: `Un RPG en IJava sur terminal`,
		skills: ['IJava', 'Bash'],
		start_date: ``,
		end_date: ``,
		desc: ``,
		icon: `images/works/the-legend-of-words.png`,
		images: [`images/works/the-legend-of-words.png`],
		link: ``,
		school: true,
	},
	{
		title: `Mystic Mirror`,
		type: `Web`,
		mini_desc: `Escape Game`,
		skills: ['Genially', 'Dall•E'],
		start_date: `06-03-2023`,
		end_date: `31-05-2023`,
		desc: ``,
		icon: `images/works/mystic-mirror.bmp`,
		images: [`images/works/mystic-mirror.bmp`],
		link: `https://view.genial.ly/6405b9ea7b728e0011a3a725/interactive-content-mystic-mirror`,
		school: true,
	},
	{
		title: `Ubisoft Mobilities`,
		type: `Web`,
		mini_desc: `Plateforme de covoiturage`,
		skills: ['HTML', 'CSS', 'PHP'],
		start_date: ``,
		end_date: ``,
		desc: ``,
		icon: `images/works/ubisoft-mobilities.bmp`,
		images: [`images/works/ubisoft-mobilities.bmp`],
		link: ``,
		school: true,
	},
	{
		title: `Base de données des jeux olympiques`,
		type: `Autre`,
		mini_desc: `Requêtes et statistiques`,
		skills: ['SQL', 'PostgreSQL', 'Statistiques'],
		start_date: `09-05-2023`,
		end_date: `19-05-2023`,
		desc: ``,
		icon: `images/works/bdd-jo.bmp`,
		images: [`images/works/bdd-jo.bmp`],
		link: `https://docs.google.com/document/d/1lnZHrfELGxW9rBTdCAiSH66QLUPrtS7Zakc1UuipaWE/edit?usp=sharing`,
		school: true,
	},
	{
		title: `Machine Virtuel`,
		type: `Autre`,
		mini_desc: `Installation et configuration`,
		skills: ['SQL', 'PostgreSQL', 'Statistiques'],
		start_date: ``,
		end_date: ``,
		desc: ``,
		icon: `images/works/debian-user-guide.bmp`,
		images: [`images/works/debian-user-guide.bmp`],
		link: ``,
		school: true,
	},
];
